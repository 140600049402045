<template>
  <div class="modal">
    <div class="model-wrapper">
      <div class="header">
        <h5>Copy {{ productNum }} products to …</h5>
      </div>
      <div class="content">
        <a-radio-group v-model="value" @change="onChange">
          <div v-for="(item, index) in storeList" :key="index" class="item">
            <a-radio :value="item.shopId" />
            <div class="title text-ellipsis">
              <span>{{ item.name }}</span>
              <label>{{ item.url ? item.url : "No Sales Channel" }}</label>
            </div>
            <img v-if="item.type === 1" src="../../assets/img/shopify.svg">
            <img
              v-if="item.type === 2"
              src="../../assets/img/retail-store.svg"
            >
            <img v-if="item.type === 3" src="../../assets/img/woocommerce.svg">
          </div>
        </a-radio-group>
        <div class="btns">
          <a-button @click="returnPage">Cancel</a-button>
          <a-button
            type="primary"
            :disabled="!value"
            @click="duplicate"
          >Duplicate</a-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { productCopy } from '@/api/product'
import { shopList } from '@/api/woocom'
export default {
  data() {
    return {
      productNum: '',
      value: '',
      formStoreId: null,
      storeList: null,
      customProductIds: []
    }
  },
  mounted() {
    this.getShopList()
    this.customProductIds = JSON.parse(this.$route.query.customProductId)
    this.productNum = this.customProductIds.length
    this.formStoreId = this.$route.query.shopId
  },
  methods: {
    async getShopList() {
      const { data } = await shopList()
      const storeList = data.records
      for (let i = 0; i < storeList.length; i++) {
        if (this.formStoreId === storeList[i].shopId) {
          storeList.splice(i, 1)
          break
        }
      }
      this.storeList = storeList
    },
    async duplicate() {
      const { code } = await productCopy({
        customProductIds: this.customProductIds,
        toStoreId: this.value
      })
      if (code === 200) {
        this.$message.success('The product has been duplicated', 10)
        this.$router.go(-1)
      } else {
        this.$message.error('copy failed')
      }
    },
    async onChange(e) {
      console.log(e)
    },
    returnPage() {
      this.$router.go(-1)
    }
  }
}
</script>
<style scoped lang="scss">
.ant-radio-group {
  width: 100%;
}
.modal {
  width: 425px;
  margin: auto;
  .model-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 16px;
    border-radius: 3px;
    background-color: #fff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.25), 0 1px 4px rgba(0, 0, 0, 0.1);
    .header {
      position: relative;
      min-height: 36px;
      margin-bottom: 20px;
      h5 {
        margin: 0;
        font-size: 24px;
        font-weight: 500;
        font-weight: bold;
      }
    }
    .content {
      .item {
        border-top: 1px solid rgba(0, 0, 0, 0.08);
        display: flex;
        align-items: center;
        position: relative;
        height: 72px;
        padding: 17px 20px 17px 24px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.08);
        transition: background 0.1s ease;
        .title {
          margin-left: 16px;
          display: flex;
          width: 70%;
          flex-direction: column;
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          vertical-align: middle;
          label {
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: block;
            font-size: 13px;
            color: #949494;
          }
        }
        img {
          width: 35px;
          height: 35px;
          background-color: #f7f7f7;
          border-radius: 50%;
          margin-left: 20px;
        }
      }
      .btns {
        text-align: right;
        margin-top: 15px;
        button {
          margin-left: 30px;
        }
      }
    }
  }
}
</style>
